<template>
  <div>
    <router-view />

    <v-alert
      v-if="env != 'production'"
      type="warning"
      dismissible
      dense
      style="position: fixed; bottom: 0; right: 0; margin: 15px; z-index: 6"
    >
      <div style="padding-right: 10px">
        <p class="mb-1" style="font-weight: 900">Atenção!</p>
        <p class="lh-2">
          Você está usando a versão de
          <b class="font-weight-bold">{{ envTranslation[env] }}</b
          >.
        </p>
        <p class="lh-1 text-12 mb-0">
          Ingressos adquiridos nesse ambiente <b>não são válidos</b>.
        </p>
        <a href="//ticketme.app">Acessar versão oficial</a>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const { VUE_APP_VERCEL_ENV } = process.env;
import { name } from "@/themeConfig.js";

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "App",
      // all titles will be injected into this template
      titleTemplate: `%s | ${name}`,
    };
  },
  data() {
    return {
      name,
      env: "development",
      envTranslation: {
        development: "desenvolvimento",
        preview: "pré-lançamento",
        production: "produção",
      },
    };
  },
  methods: {
    setDarkMode(data) {
      this.$vuetify.theme.dark = data;
    },
  },
  mounted() {
    this.$nextTick(() => {
      let darkMode = this.$store.getters.getThemeMode.isDark || false;
      this.setDarkMode(!!darkMode);
    });
  },
  created() {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      setDarkMode(e.matches);
    });
    this.env = VUE_APP_VERCEL_ENV || "development";
  },
};
</script>

<style lang="scss">
.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.non-draggable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@for $i from 1 through 16 {
  .lh-#{$i} {
    line-height: #{1 + (($i - 1) * 0.1)}rem !important;
  }
}

.v-expansion-panels.outline {
  div.v-expansion-panel {
    border: 2px solid rgba(0, 0, 0, 0.12);
    transition: border-width 1s ease;
    &:not(:first-child) {
      border-top: none; // Remove a borda superior
    }

    &.v-expansion-panel--active {
      border: 2px solid rgba(0, 0, 0, 0.12);
    }
  }
}

@for $i from 0 through 16 {
  .gap-x-#{$i} {
    column-gap: #{$i * 0.25}rem;
  }
  .gap-y-#{$i} {
    row-gap: #{$i * 0.25}rem;
  }

  .gap-#{$i} {
    gap: #{$i * 0.25}rem;
  }
}
</style>
